import React from 'react';
import { Link, navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { ReactComponent as LogoWeb } from '../../images/trenton_ducati_com.svg';
import { ReactComponent as LogoMobile } from '../../images/trenton_ducati_com.svg';

import { routes } from '../../services/navigation/navigation.service.routes';

import './ApplicationLogo.scss';

const ApplicationLogo = ({ notLink }) => {
  const onClick = () => navigate(routes.root);

  const Elem = notLink ? 'span' : Link;
  const props = {
    className: 'ApplicationLogo'
  };

  if (!notLink) {
    props.onClick = onClick;
    props.to = routes.root;
    props['aria-label'] = 'Site logo';
  }

  return (
    <Elem {...props}>
      <LogoWeb className="DesktopIcon" />
      <LogoMobile className="MobileIcon" />
    </Elem>
  );
};

ApplicationLogo.propTypes = {
  notLink: PropTypes.bool,
};

export default ApplicationLogo;
